<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;"
	 v-loading="laoding">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->
		<!-- 面包屑END -->
		<div style="height: 111px;background: #FFFFFF;width: 100%;margin-top: 10px;" class="border-F2F2F2">
			<div style="padding: 15px 140px;">
				<el-steps :active="active" :align-center="true" finish-status="success">
					<el-step title="待支付订单"></el-step>
					<el-step title="填写订单"></el-step>
					<el-step title="支付订单"></el-step>
					<el-step title="支付结果"></el-step>
				</el-steps>
			</div>
		</div>
		<div class="">
			<div v-for="(item, index) in orderList" :key="index">
				<div class="font-size16 font-weight700 margin-b-10 margin-t-10 color-theme">
					订单号：{{item.fOrderNumber}}
				</div>
				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div>
						<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 flex-row-space-between-center">收货人信息</div>
						<div v-if="JSON.stringify(item.address) != '{}'">
							<div class="price-box active border-color-theme flex-row-space-between-center pointer box-sizing" style="background: #f8f9fe;"
							 @mouseover="mouse = -99" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center">
									<div class=" flex-column-align-center margin-l-10 color-theme ">
										<div>{{ item.address.fLinkman }}</div>
										<div>{{ item.address.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 color-theme">工程名称：{{ item.fProjectName }}</div>
									<div class="margin-l-15 color-theme">收货地址：{{ item.address.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10 " v-show="mouse == -99" v-if='item.fNegotiateStatus == 2 ? false:true'>
									<i class="el-icon-edit font-color-666 font-size18 pointer" style="padding-right: 20px;" @click="EditAddress(item.address)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18 pointer" @click="DeleteAddressChecked"></i> -->
								</div>
							</div>
							<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
							<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme" v-for="(addressitem, addressindex) in item.noDefaultAddress"
							 :key="addressindex" style="background: #f8f9fe;" v-if="addressStatus && addressIndex == index" @mouseover="mouse = addressindex"
							 @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center font-color-999" @click="addressChoose(addressitem, index)">
									<div class=" flex-column-align-center margin-l-10 font-color-999">
										<div class="">{{ addressitem.fLinkman }}</div>
										<div class="">{{ addressitem.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 font-color-999">工程名称：{{ addressitem.fProjectName }}</div>
									<div class="margin-l-15 font-color-999">收货地址：{{ addressitem.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10" v-show="mouse == addressindex">
									<i class="el-icon-edit  font-color-666 font-size18" style="padding-right: 20px;" @click="EditAddress(addressitem)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18" @click="DeleteAddress(addressitem, addressindex)"></i> -->
								</div>
							</div>
							<div class="margin-lr-15 flex-row-align-center" v-if='item.fNegotiateStatus == 2 ? false:true'>
								<div class="pointer margin-r-10" @click="noDefaultAddressShow(index)">
									{{ addressStatus && addressIndex == index ? '收起地址' : '展开地址' }}
									<i class=" " :class="addressStatus && addressIndex == index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								</div>
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
						<div v-else>
							<div class="price-box active flex-row-space-between-center box-sizing padding-lr-10 font-color-999" style="background: #f8f9fe;">
								暂无可用收货地址
							</div>
							<div class="padding-lr-13 margin-t-15 margin-b-15">
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
					</div>
					<!-- 收货人信息结束 -->

					<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">商品信息</div>
					<div class=" margin-lr-15">
						<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #f8f9fe;">
							<p class="width756" style="text-align: center;">商品信息</p>
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
						<div class="wrap">
							<div class=" ">
								<div class="">
									<div class="flex-row-space-between-center" style="height: 123px;">
										<div class="flex-row-start-start">
											<div class="flex-row-space-between-center" style="margin-left: 15px;">
												<div class="box-shadow-all imageRadius" style="">
													<el-image class="imageRadius" :src="item.fPicturePath"></el-image>
												</div>
											</div>
											<div style="margin-left: 19px;">
												<div class="">
													<div class="font-size12 font-color-fff radius2 margin-r-5" style="display: inline-block;background-color:#40C6A9;padding: 0 3px;">企</div>
													<div class="tag-box font-size12 font-color-fff radius2 margin-r-5" style="display: inline-block;" v-if="item.fShopTypeID == 1">{{ item.fShopTypeName }}</div>
													{{ item.fGoodsFullName }}
												</div>
												<div class="flex-row" style="margin-top: 8px;">
													<div class="tag-box-red  font-size12 " v-if="item.text">{{ item.text }}</div>
													<div class="tag-box-blue color-theme border-theme font-size12" v-if="item.dei">{{ item.dei }}</div>
												</div>
												<!-- <div>工程：达坂城第一工程</div> -->
											</div>
										</div>
										<div class=" ">
											<div class="flex-row">
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<el-input-number  :disabled='item.fNegotiateStatus == 2? true:false'   size="small" v-model="item.fPlanAmount"   
													:min="item.fMinSaleAmount" :max="99999" :precision="item.fPrecision"  :step="item.fAdjustmentRange"
													 @change="valChange(item,index)" @blur="valBlur(item,index)" @focus="getInputFocus($event)"></el-input-number>
												</div>
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<p class="font-weight700">￥{{item.fGoodsPrice | NumFormat}}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="border-top-F2F2F2">
									<div class="margin-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center" style="align-items: center;height:30px">
													配送方式：
													  <el-radio-group v-model="item.fDeliveryID" @change="deliveryMethodClick(item.fDeliveryID,index)">
															<el-radio :label="item2.fDeliveryID + ''" :disabled='item.fNegotiateStatus == 2? true:false'  v-for="(item2, index2) of item.deliveryDtos" >
																	{{item2.fDelivery}}
															</el-radio>
													  </el-radio-group>
												</div>
											</el-col>
											<el-col :span="8" v-if="item.fDeliveryID.toString() == '1'">
												<div class="flex-row-align-center">
													选承运商：
													<el-select   style="margin-left: 27px;" :disabled='item.fNegotiateStatus == 2? true:false' v-model="item.carrierData" size="mini" @change="carrierClick(item.carrierData,index)">
														<el-option v-for="(item4, index4) in item.carrierDtos" :key="index4" :label="item4.fCustomerName" :value="item4.fCarrierID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="8" v-if="item.fDeliveryID == 1">
												<div v-if='item.fNegotiateStatus == 2? false:true'   class="flex-row-align-center font-color-FF0000 font-weight700" style="float:right;color:black">
													运费单价:￥ <span v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span v-else>{{item.fFreightPriceTax}}</span>  
												</div>
												<div v-else class="flex-row-align-center font-color-FF0000 font-weight700" style="float:right">
													运费单价:￥ <span v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span v-else>{{item.ftFreightPrice}}</span>  
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="margin-top: 6px;margin-bottom: 7px;">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													运输方式：
													<el-select :disabled='item.fNegotiateStatus == 2? true:false'   v-model="item.fBusinessTypeID" size="mini" @change="BusinessTypeChoose(index)">
														<el-option v-for="(item7, index7) in item.BusinessType" :key="index7" :label="item7.fBuTypeName" :value="item7.fBusinessTypeID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="7" v-if="item.fDeliveryID.toString() == '1'">
												<div class="flex-row-align-center">
													开具运输发票：
													<el-select  :disabled='item.fNegotiateStatus == 2? true:false'  v-model="item.fIsNeedFInvoiceStatus" size="mini" @change="transportationInvoiceClick(item.fIsNeedFInvoiceStatus,index)">
														<el-option v-for="(item7, index7) in item.transportationInvoiceDtos" :key="index7" :label="item7.title"
														 :value="item7.id"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="5" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center font-color-999 font-size12" style="height: 30px;">
													<span class="iconfont icon-tishi5 font-color-FFAA00 margin-r-5"></span>
													运费单价,不含装卸等其他费用
												</div>
											</el-col>
										</el-row>
									</div>
								</div>
								<div style="padding-top: 8px;" class="divcss5-3"></div>
								<div style="height: 84px;">
									<div class="flex-column-start-end font-size12 " style="margin-right: 15px;line-height: 22px;">
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											货款金额：
											<div>¥{{item.fGoodsPrice * item.fPlanAmount | NumFormat}}</div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											运费金额：
											<div v-if='needfNegotiateStatus == 2? false:true'>+ ¥<span v-if="item.fDeliveryID == 1">{{item.freightAmount | NumFormat  }}</span><span v-if="item.fDeliveryID == 2">{{item.freightAmount * 0 | NumFormat  }}</span> </div><div v-else><span v-if="item.fDeliveryID == 1">{{ffMoneyReceivable | NumFormat}}</span><span v-if="item.fDeliveryID == 2">{{ffMoneyReceivable *0 | NumFormat}}</span> </div>
										</div>
										<div class="flex-row-space-between-center" style="width: 180px;">
											<div>小计：</div>
											<div class="font-color-FF0000 font-weight700">¥<span v-if='item.fNegotiateStatus == 2?false:true'>{{ getOrderPrice(item)  | NumFormat}}</span> <span v-else>{{ specialPrice(item) +ffMoneyReceivable  | NumFormat}}</span> </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 发票信息 -->
			<div class="flex-row">
				<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-r-10">发票信息</div>
				<div class="flex-row-align-center border-box">
					开具货款发票：
					<!-- <el-select v-model="fIsNeedInvoiceStatus" size="mini" @change="paymentForGoodsInvoiceClick(fIsNeedInvoiceStatus)">
						<el-option v-for="(item, index) in fIsNeedInvoiceStatusList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select> -->
					<el-radio v-model="fIsNeedInvoiceStatus" :label="1">需要</el-radio>
					<el-radio v-model="fIsNeedInvoiceStatus" :label="0">不需要</el-radio>
				</div>
			</div>
			<div class="border-F2F2F2">
				<div class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;"
				 v-if="invoiceInformation.feMail!=null">
					<div class="flex-row-align-center">
						<i class="el-icon-s-ticket font-size18"></i>
						<p class="font-size14" style="padding-left: 6px;">发票信息</p>
						<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
					</div>
					<i class="el-icon-arrow-up font-size18 pointer" :class="invoiceInformation.invoiceInformationTipsShow?'el-icon-arrow-up':'el-icon-arrow-down'"
					 @click="invoiceInformationTipsShowClick"></i>
				</div>
				<div v-else class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
					<div class="flex-row-align-center"><a class="font-size14 pointer hover" style="padding-left: 6px;" @click="addInvoice('Add')">+维护发票收件地址</a></div>
				</div>

				<div class="font-size12 font-color-666 bg-fff" style="padding: 10px 21px;" v-if="invoiceInformation.invoiceInformationTipsShow">
					<div class="margin-b-5">
						<div class="flex-row line-height22">
							<div>发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row line-height22">
							<div>发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row" :class="getUserInfo.fCommerceTypeID=='1'?'margin-b-10 padding-b-10 border-bottom-F2F2F2':'line-height22'">
							<div>{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row line-height22">
								<div>住所：</div>
								<div>{{invoiceInformation.fAddress}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row margin-b-10 padding-b-10 border-bottom-F2F2F2">
								<div>银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div>
								<div class="flex-row">
									<div>邮寄地址：</div>
									<div>{{invoiceInformation.fFullPathAddress}}</div>
								</div>
								<div class="flex-row line-height22">
									<div>收件人：</div>
									<div>{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
								</div>
								<div class="flex-row">
									<div>邮箱：</div>
									<div>{{invoiceInformation.feMail}}</div>
								</div>
							</div>
							<div class="flex-row-align-center pointer hover color-theme" @click="addInvoice('Edit')">
								修改发票收件地址
							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- 发票信息结束 -->

			<div class="flex-row-right bottomBox font-size16" style="line-height: 30px;margin-top: 66px;margin-bottom: 50px;">
				<!-- <div class="flex-row-space-between-center font-size14 font-color-666"> -->
				<div class="flex-row-center-center" style="margin-right: 30px;">
					<div class="flex-row-align-center">
						<div class="flex-row">
							<p class="font-color-FF0000 ">{{ this.orderList.length }}</p>
							<p class="font-color-999">件商品</p>
							<p class="font-color-000">，实付金额：</p>
							<p class="font-color-FF0000 font-weight700">¥{{ getOrderTotal() | NumFormat }}</p>
						</div>

						<!-- <router-link to="/paymentOrder"> -->
						<!-- <router-link to="/shoppingCart/fillOrder/paymentOrder"> -->
						<el-button v-if='isPrice'    style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" :loading="createLoading"
						 @click="asktalkAboutPrice">运费议价</el-button>
						<button style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" @click="updateOrder">提交订单</button>
						<!-- </router-link> -->
					</div>
				</div>
				<!-- </div> -->
			</div>
		</div>

		<!-- 收货地址 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="pageTitle + '收货地址'" :visible.sync="addVisible" @close="handleClose"
		 width="600px" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="130px" label-position="right">
					<el-form-item label="收货人" prop="fLinkman">
						<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="fLinkPhone">
						<el-input v-model="addressForm.fLinkPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="行政区域" class="areaBox" prop="area">
						<el-col :span="24">
							<selectArea v-model="addressForm.area"></selectArea>
						</el-col>
						<!-- <div class="mapBtn pointer font-size14 font-color-666" @click.once="getMyAddress">
							<span class="iconfont icon-dingwei margin-r-6"></span>获取当前地址
						</div> -->
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<!-- <el-form-item label="地址类型" prop="fAddressTypeID">
						<el-select v-model="addressForm.fAddressTypeID" clearable placeholder="请选择地址类型">
							<el-option v-for="item in addressList" :key="item.fAddressTypeID" :label="item.fAddressType" :value="item.fAddressTypeID">
							</el-option>
						</el-select>
					</el-form-item> -->
					<!-- <el-form-item label="电子邮箱" prop="feMail">
						<el-input v-model="addressForm.feMail" placeholder='请输入电子邮箱' />
					</el-form-item> -->
					<el-form-item label="工程名称" prop="fProjectName">
						<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
					</el-form-item>
					<el-form-item label="设置默认地址" prop="addressType">
						<el-switch v-model="addressForm.fIsDefault"></el-switch>
					</el-form-item>
					<el-form-item>
						<div id="container" style="height: 200px;" v-show="mapShow"></div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 维护发票信息 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="invoiceType=='Add'?'维护':'编辑'+'发票收件地址'" :visible.sync="addInvoiceStatus"
		 width="600px" :before-close="addInvoiceClose" :close-on-click-modal="false" class="invoice">
			<div class=" " style="">
				<div class="font-size12 font-color-666 bg-F2F4FD" style="margin-bottom: 5px;">
					<div class="invoice-box" style="line-height: 22px;padding: 10px 15px;">
						<div class="font-size14 font-weight700 font-color-000">{{getUserInfo.fCommerceTypeID=='1'?'个人：':'企业：'}}发票信息</div>
						<div class="flex-row">
							<div style="width: 70px;">发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row">
								<div style="width: 80px;">住所：</div>{{invoiceInformation.fAddress}}
							</div>
							<div class="flex-row">
								<div style="width: 70px;">公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<!-- <div class="flex-row">
							<div style="width: 70px;">邮寄地址：</div>
							<div>新疆乌鲁木齐市新市区经济开发区卫星路 499号秦郡大厦B座501室</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">收件人：</div>
							<div>王俊凯 16612345789</div>
						</div> -->
					</div>
				</div>
				<el-form ref="addInvoiceForm" :rules="rules" :model="addInvoiceForm" label-width="140px" label-position="right">
					<el-form-item label="收件邮箱" prop="feMail">
						<el-input v-model="addInvoiceForm.feMail" />
					</el-form-item>
					<el-form-item label="收件姓名" prop="fLinkman">
						<el-input v-model="addInvoiceForm.fLinkman" />
					</el-form-item>
					<el-form-item label="联系电话" prop="fLinkPhone">
						<el-input v-model.number="addInvoiceForm.fLinkPhone" maxlength="11" />
					</el-form-item>
					<el-form-item label="邮寄地址" prop="area">
						<div class="flex-row">
							<selectArea v-model="addInvoiceForm.area"></selectArea>
						</div>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addInvoiceForm.fAddress" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="addInvoiceStatus = false">取 消</el-button>
				<el-button size="small" type="primary" @click="invoiceSubmitForm('addInvoiceForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	export default {
		components: {
			breadcrumb,
			selectArea
		},
		data() {
			const rulesloginId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
					if (phoneNumberRul.test(value)) {
						callback();
					} else {
						callback(new Error('请输入正确的手机号码'));
					}
				}
			}
			return {
				numnum:0.01,
				createLoading: false,
				isPrice:false,
				ffMoneyReceivable:'',//新得到的运费
				laoding: false,
				needfNegotiateStatus:'',
				invoiceInformation: {}, //发票信息
				orderIDList: [], //传递过来的订单id list
				fFreightPrice: 0,
				orderList: [], //订单列表
				goodsOrder: [], //获取商品价格和概要信息需要的参数
				useGoodsInformation: [], //组织需要的参数
				chooseAddress: [], //地址信息

				addInvoiceStatus: false,
				addInvoiceForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: ['', '', '', ''],
				},
				rules: {
					feMail: [{
						required: true,
						message: '请输入收件邮箱',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: '请输入有效的邮箱',
						trigger: 'blur'
					}],
					fLinkman: [{
						required: true,
						message: '请输入收件姓名',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([a-zA-Z0-9\u4e00-\u9fa5\·]{1,10})$/,
						message: '您的真实姓名格式错误,请输入英文或汉字!',
						trigger: 'blur'
					}],
					fLinkPhone: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'blur',
					}, {
						required: true,
						pattern: /^1[34578]\d{9}$/,
						message: '请输入11位正确手机号码',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur',
					}],
					fAddress: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur',
					}],
				},
				invoiceType: '', //发票收件信息新增还是编辑

				fIsNeedInvoiceStatus: 1, //是否开具货款发票

				mouse: -1,


				mapShow: false, //地图
				maplat: '',
				maplng: '',
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				addVisible: false,
				pageTitle: "新增",
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: ['', '', '', ''],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: 1,
					fIsDefault: false
				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: 'blur'
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					feMail: [{
							required: true,
							message: '请填写邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请填写正确的邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}],
					fAddressTypeID: [{
						required: true,
						message: '请选择地址类型',
						trigger: 'blur'
					}],
				},

				addressStatus: false, //除了默认地址以外其他地址是否显示
				addressIndex: 0, //除了默认地址以外显示哪一个商品的其他地址

				active: 2,

				// 提交数组
				submitList: [],
				// 已提交下标数组
				submitListIndex: 0,
				// 提交成功数组
				submitSuccessList: [],
				//判断议价数据是否有成功的
				submitSuccessListPrice:[],
				needPriceIndex:0,
				//承运商议价所需数组
				submitListPrice:[],
				orderLoading:false,
				resultdata:[],
				orderListData : [],
			};
		},
		async mounted() {
			console.log('this.$store.getters.getCommonData', this.$store.getters.getCommonData);
			this.orderIDList = this.$store.getters.getCommonData;
			this.getList();
		},
		// created() {
		// 	if (this.id != '') {
		// 		this.getOrderInformation(this.id);
		// 	}
		// },
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//获取全部信息
			getList() {
				console.error(this.goodsOrder)
				this.ApiRequestPost('api/mall/ebsale/order/b2b-new-update-order-logic', {
					fOrderID: this.orderIDList[0].id
				}).then(
					res => {
						console.log(res)
						// res.obj.couponInfoAvailableDtos = res.obj.fCoupon
						this.orderListData = res
						res.obj.items = res.obj.fAddress
						res.obj.goodList = []
						res.obj.fGoodsDeliverys = []
						res.obj.freightApply = []
						res.obj.goodsPrice = []
						res.obj.fGoods.fGoodsDeliverys.map(item => {
							console.log(item.fPriceDelivery)
							if (item.fPriceDelivery && item.fPriceDelivery != '') {
								res.obj.fGoodsDeliverys.push(item.fPriceDelivery)
							}
							if (item.freightApply && item.freightApply != '') {
								res.obj.freightApply.push(item.freightApply)
							}
							if (item.fPrice && item.fPrice != '') {
								res.obj.goodsPrice.push(item.fPrice)
							}
						})
						this.forders = res.obj.forder;
						res.obj.fTransport = res.obj.fGoods.fTransport
						// res.obj.fGoodsDeliverys = res.obj.fGoodsList[0].fGoodsDeliverys
						this.getOrderInformation(res);
					},
					rej => {}
				);
			},
			//获取地址列表
			getProjectList(res) {
				////console.log(this.tabindex+"this.tabindex");
				this.ApiRequestPost('api/mall/eb-customer/address/get-enable-list-by-current-user-address', {
					addressTypeId: 1, //地址类型参数
					maxResultCount: 99,
					skipCount: 0
				}).then(
					result => {
						console.log("获取地址列表", result);
						if (result.obj.items.length > 0) {
							this.orderList.forEach((item, index) => {
								result.obj.items.forEach((childitem, index) => {
									childitem.fFullPathAddress = childitem.fFullPathAddress.replace(/\\/g, '');
								});
								// let findData = result.obj.items.find(findItem => findItem.fAddressID == item.address.fAddressID);
								// if (findData) {
								// 	// console.log('findData', findData);
								// } else {
								// 	let findDefault = result.obj.items.find(findItem => findItem.fIsDefault == 1)
								// 	if (findDefault) {
								// 		item.address = findDefault;
								// 	} else {
								// 		item.address = result.obj.items[0];
								// 	}
								// }
								let findData1 = result.obj.items.find(findItem => findItem.fGovernmenAreaID == item.address.fGovernmenAreaID);
								if (findData1) {
									item.noDefaultAddress = result.obj.items;
									// console.log('findData', findData);
								} else {
									result.obj.items.unshift(item.address);
									item.noDefaultAddress = result.obj.items;
								}
							})
						} else {
							// this.orderList.forEach((item, index) => {
							// 	item.address = {};
							// })
						}
					},
					rej => {}
				);
			},
			// 当前订单应支付金额
			getOrderPrice(item) {
				console.log(item.freightAmount,'000000000000000000000000000000')
				if(item.freightAmount == undefined){
					item.freightAmount = 0
				}
				return item.fGoodsPrice * item.fPlanAmount + item.freightAmount
			},
			//用在议价单的金额运算上
			specialPrice(item, index){
				return (item.fGoodsPrice * 100 * item.fPlanAmount) / 100 - item.fPointMoney - item.fCouponMoney 
			},
			// 订单总额
			getOrderTotal() {
				let total = 0;
				if(this.needfNegotiateStatus !=2){
					this.orderList.forEach(item => {
						total = total + this.getOrderPrice(item)
					})
				}else if(this.needfNegotiateStatus == 2){
					this.orderList.forEach(item => {
						total = total + this.specialPrice(item, "总额") + this.ffMoneyReceivable
					})
				}
				
				return total
			},
			//获取订单信息
			getOrderInformation(res) {
				// this.ApiRequestPostNOMess('api/mall/ebsale/order/get-batch',
				// 	this.orderIDList
				// ).then(
				// 	res => {
						console.log(res)
						if (res.obj.forder.orders.length > 0) {
							this.orderList = [];
							console.log("获取订单信息", res);
							let data = res.obj.forder.orders;
							res.obj.forder.orders.forEach((item, index) => {
								item.fDeliveryAddress = item.fDeliveryAddress.replace(/\\/g, '');
								item.address = {
									fProjectName: item.fProjectName,
									fLinkman: item.fConsignee,
									fLinkPhone: item.fConsigneePhone,
									fFullPathAddress: item.fDeliveryAddress,
									fGovernmenAreaID: item.fGovernmenAreaID,
									fAddressID: item.fAddressID
								}
								item.carrierData = item.fCarrierID;
								this.getFreightPrice(); //承运商数据
								item.carrierPopupList = []; //加承运商数组
								item.transportationInvoiceDtos = [{
										title: '需要',
										id: 1
									},
									{
										title: '不需要',
										id: 0
									}
								];
								item.fFreightPriceTax = 0; //需要运输发票之后含税运费
								item.fBusinessTypeID = '1'; // 运输方式ID
								this.fIsNeedInvoiceStatus = item.fIsNeedInvoiceStatus;
								
							});

							this.orderList = res.obj.forder.orders;
							this.needfNegotiateStatus =  this.orderList[0].fNegotiateStatus
							this.ffMoneyReceivable = this.orderList[0].ffMoneyReceivable
							for(let item of this.orderList){
								if(this.needfNegotiateStatus != 2){
									if(item.fDeliveryID == 1){
										this.isPrice = true
									}else{
										this.isPrice = false
									}
								}else{
									this.isPrice = false
								}
								
							}

							let params = []; //获取提货方式接口参数
							let params1 = []; //获取商品图片接口参数
							this.orderList.forEach((item, index) => {
								let data = item.fGoodsID; //获取提货方式参数
								params.push(data);
								let data1 = { //获取商品图片接口参数
									id: item.fGoodsID
								}
								params1.push(data1);
							})
							this.getBusinessType(res);
							this.getDeliveryByAreaid(false,res); //获取提货方式
							this.getOrderPicture(params1); //获取商品图片
							console.log("获取订单信息", this.orderList);
							this.getProjectList(res); //获取地址
							this.getInvoiceInfo(res); //获取发票信息
						}
				// 	},
				// 	error => {}
				// );
			},
			//获取商品价格
			async getGoodsPrice(res) {
			let xxx = []
			for (let item of this.orderList) {
				console.log(item,'cao')
				xxx.push({
					 fGoodsID: item.fGoodsID,
					 fDeliveryID: item.fDeliveryID
				})
			}
			// xxx.push(this.orderList[0].fGoodsID)
			this.ApiRequestPost('api/mall/ebsale/b2b-inquiry-apply-goods-price/get-b2b-price-by-goods-and-deliver', xxx)
				.then(
					res => {
						res.obj.forEach((item, index) => {
							this.orderList.forEach((childitem, childindex) => {
								if(!item.fGoodsMinPrice){
									for(let sonitem of item.deliverys){
										if(sonitem.fDeliveryID == childitem.fDeliveryID){
											childitem.fGoodsPrice = sonitem.fGoodsPrice
										}
									}
									item.fGoodsMinPrice = item.deliverys[0].fGoodsPrice
								}
								if (item.fGoodsID == childitem.fGoodsID) {
									// childitem.fGoodsPrice = item.deliverys[0].fGoodsPrice; // 商品价格
									for(let sonitem of item.deliverys){
										if(sonitem.fDeliveryID == childitem.fDeliveryID){
											childitem.fGoodsPrice = sonitem.fGoodsPrice
										}
									}
									childitem.fGoodsMinPrice = item.fGoodsMinPrice;
									if(childitem.fGoodsPrice != childitem.fGoodsMinPrice || childitem.deliveryDtos.length == 0){
										this.$message('商品不在当前收货地址销售，请重新选择收货地址');
									}
									if(childitem.fDeliveryID == 1){
										this.isPrice = true
									}else{
										this.isPrice = false
									}
									childitem.fPointPrice = item.deliverys[0].fPointPrice; // 积分价格
									childitem.fMaxPointMoney =item.deliverys[0].fMaxPointMoney; // 商品最大可使用积分
									childitem.pointRMBPrice = item.deliverys[0].pointRMBPrice; // 积分换算率
									childitem.fMinSaleAmount = item.deliverys[0].fMinSaleAmount; // 最小销量
									childitem.fAdjustmentRange =item.deliverys[0].fAdjustmentRange; // 销量调整幅度 
									childitem.fPrecision =item.deliverys[0].fPrecision; // 销量小数位数
									// fIsEnableUseCoupon 0无法使用1可以使用优惠券
									// childitem.fIsEnableUseCoupon = item.fIsEnableUseCoupon;
									// childitem.fUsePoint = this.calculationEndBalance(childitem); // 用户使用积分数默认最大积分
									// this.inputBlur(childitem, childindex);
								}
							});
						});
						console.log(this.orderList,'9999999999999')
						// this.getCouponInfo(); // 获取优惠券
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
			},
			//根据商品ID查询当前商品提货方式
			// 根据商品ID查询当前商品配送方式
			async getDeliveryByAreaid(val,res) {
				await this.orderList.forEach((item, index) => {
					this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-b2b-goods-current-price-deliverys', {
							goodsId: item.fGoodsID, // 商品ID
							fGovernmenAreaID: item.fGovernmenAreaID // 行政区域ID
						})
						.then(
							res => {
								if (res.obj.length > 0) {
									// res.obj.unshift({
									// 	fDeliveryID: -1,
									// 	fDelivery: '请选择配送方式'
									// });
								}
								res.obj.forEach(item1 => {
									if(!item.fDeliveryID || item.fDeliveryID=="" ){
										item.fDeliveryID = item1.fDeliveryID.toString()
									}
									if(val == true){
										item.fDeliveryID = item1.fDeliveryID.toString()
									}
								})
								item.deliveryDtos = res.obj;
								this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							},
							error => {}
						);
				});
				this.getGoodsPrice(res)
				this.getFreightPrice(res); //承运商数据
			},
			//配送方式点击事件
			deliveryMethodClick(deliveryid, index) {
				console.log(this.orderList,'000000000000')
				this.orderList[index].fDeliveryID = deliveryid;
				for(let item of this.orderList){
					//判断这个订单是否是议过价的订单
					if(this.needfNegotiateStatus !=2){
						if(item.fDeliveryID == 1){
							this.isPrice = true
						}else{
							this.isPrice = false
						}	
					}else{
						this.isPrice = false
					}
				}
				// 配送方式变更时 是否运输发票 重置为 否
				this.orderList[index].fIsNeedFInvoiceStatus = 0;
				// 配送方式变更 不是承运商代送时 选择承运商重置为请选择  运价重置为0
				if (deliveryid != 1) {
					this.orderList[index].carrierData = -1;
					this.orderList[index].fCarrierID = -1;
					this.orderList[index].freightAmount = 0;
				}
				this.getGoodsPrice(this.orderListData)
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			// 获取运输方式
			getBusinessType(res) {
				this.laoding = true;
				let goodsIDList = []
				this.orderList.forEach((item, index) => {
					goodsIDList.push({
						goodsId: item.fGoodsID
					})
				});
				this.ApiRequestPostNOMess('api/mall/ebsale/transport-business-type-rule/get-list-by-goods-id-batch', goodsIDList)
					.then(
						res => {
							res.obj.forEach((data, dataIndex) => {
								this.orderList.forEach((item, itemIndex) => {
									if (data.goodsid == item.fGoodsID) {
										// 查找汽运 并将其放置数组第一列
										let findData = data.list.find(findItem => findItem.fBusinessTypeID == "1")
										data.list.splice(data.list.indexOf(findData), 1);
										data.list.unshift(findData);
										this.orderList[itemIndex].BusinessType = data.list;
									}
								})
							})
							this.$forceUpdate();
							this.laoding = false;
							console.log('this.orderList11111', this.orderList);
						},
						error => {}
					);
			},
			// 选择运输方式  
			BusinessTypeChoose(index) {

			},

			//获取商品图片
			getOrderPicture(params1) {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-batch', params1).then(
					res => {
						console.log("获取商品图片", res);
						// this.orderList[0].fPicturePath = this.getUploadFileURL(res.obj.fPicturePath);
						res.obj.forEach((item, index) => {
							this.orderList.forEach((orderitem, orderindex) => {
								if (item.fGoodsID == orderitem.fGoodsID) {
									orderitem.fPicturePath = item.fPicturePath;
									orderitem.fStatus= item.fStatus;//这句话是为了让this.orderList里面的fstatus来自商品自身
								}
							})
						});

						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						console.log('获取商品图片', this.orderList);
					},
					error => {}
				);
			},

			//配送方式为承运商代送时候承运商数据
			//配送方式为承运商代送时候承运商数据
			async getFreightPrice() {
				let apiData = []
				this.orderList.forEach((item, index) => {
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID,
						fGoodsID: item.fGoodsID
					})
				});
				await this.ApiRequestPostNOMess('api/mall/ebsale/freight-price/get-b2c-freight-price-by-gov-and-goods-id-batch',
						apiData)
					.then(
						res => {
						res.obj.forEach((data, dataIndex) => {
								for (let item of this.orderList) {
									if (data.fGoodsID == item.fGoodsID) {
										let carrierDtosData = {
											fCarrierID: -1,
											fCustomerName: '请选择承运商'
										}
										if (data.freightPrice.length == 1 && data.freightPrice[0].fCarrierID == '0') {
											data.freightPrice = [carrierDtosData];
											item.carrierData = -1;
											item.fCarrierID = -1;
											item.carrierDtos = data.freightPrice;
											return;
										}
										item.carrierDtos = data.freightPrice;
										var falg = false;
												item.carrierDtos.forEach((val,index)=>{
													if (item.carrierData == val.fCarrierID) {
														falg = true;
													}
												});
												if(!falg){
														item.carrierData = -1;
												}
										item.carrierDtos.unshift(carrierDtosData);
										// 判断当前订单是否 选择开运输发票
										// 根据当前订单的承运商ID 获取 承运商数据
										if (item.fDeliveryID.toString() == "1") {
											let freightPriceData = data.freightPrice.find(p => p.fFreightPriceID == item.fFreightPriceID)
											if (item.fIsNeedFInvoiceStatus == 1) {
												item.freightAmount = freightPriceData.fFreightPriceTax * item.fPlanAmount;
											} else {
												item.freightAmount = freightPriceData.fFreightPriceTax * item.fPlanAmount;
											}
											item.fFreightPriceTax = freightPriceData.fFreightPriceTax
											item.fFreightPrice = freightPriceData.fFreightPrice
										} else {
											item.freightAmount = 0;
										}

										break;
									}
								}
						});
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						},
						error => {}
					);
			},
			//承运商点击事件
			//承运商点击事件
			carrierClick(fCarrierID, index) {
				this.fFreightPriceTax = 0;
				let fMinAmount = 0;
				let fFreightPriceTax = 0;
				this.orderList[index].carrierDtos.forEach(item => {
					console.log(fCarrierID,item.fCarrierID,'查看数据')
					// 选择承运商 拿取最小运载量
					if (fCarrierID == item.fCarrierID) {
						fMinAmount = item.fMinAmount;
						if(item.fFreightPriceTax){
							fFreightPriceTax = item.fFreightPriceTax
						}
					}
				});
				this.fFreightPriceTax = fFreightPriceTax;
				if (this.orderList[index].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount);
					this.orderList[index].carrierData = -1;
					this.orderList[index].freightAmount = 0;
					return;
				}
				this.orderList[index].freightAmount = fFreightPriceTax * this.orderList[index].fPlanAmount;
				this.orderList[index].fCarrierID = fCarrierID;
				this.orderList[index].carrierData = fCarrierID;
				this.orderList[index].carrierDtos.forEach(item => {
					if (item.fCarrierID == fCarrierID) {
						this.orderList[index].fFreightPriceTax = item.fFreightPriceTax;
						this.orderList[index].fIsNeedFInvoiceStatus = 0;
					}
				})
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//运输发票选择事件
			transportationInvoiceClick(fIsNeedFInvoiceStatus, index) {
				//console.log('this.orderList', this.orderList);
				//console.log('fIsNeedFInvoiceStatus', fIsNeedFInvoiceStatus);
				if (this.orderList[index].fCarrierID == '-1') {
					this.$message('请选择承运商');
					this.orderList[index].fIsNeedFInvoiceStatus = 0;
				} else {
					console.log('111');
					this.orderList[index].fIsNeedFInvoiceStatus = fIsNeedFInvoiceStatus;
					if (fIsNeedFInvoiceStatus == 0) {
						this.orderList[index].freightAmount = this.fFreightPrice * this.orderList[index].fPlanAmount;
					} else {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[index].fPlanAmount;
					}
					console.log('this.fFreightPrice', this.fFreightPrice);
					console.log('this.orderList[index].fPlanAmount', this.orderList[index].fPlanAmount);
					console.log('this.orderList[index].freightAmount', this.orderList[index].freightAmount);

					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				}
			},

			//获取发票信息
			getInvoiceInfo() {
				this.getInvoiceAddress();
				this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/get', {}).then(
					res => {
						// console.log("获取发票信息", res);
						if (res.obj.fApproveStatus != 254) {
							this.$message('发票未审核通过');
						}
						// this.orderList.forEach((childitem, childindex) => {
						// 	childitem.invoiceInformation.fUnitName = res.obj.fUnitName;
						// 	childitem.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						// 	childitem.invoiceInformation.fBankNames = res.obj.fBankNames;
						// 	childitem.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
						// });
						console.log("获取发票信息", this.orderList);
						this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceClassName;
						this.invoiceInformation.fUnitName = res.obj.fUnitName;
						this.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						this.invoiceInformation.fAddress = res.obj.fAddress.replace(/\\/g, '');
						this.invoiceInformation.fPhone = res.obj.fPhone;
						this.invoiceInformation.fBankNames = res.obj.fBankNames;
						this.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
					},
					error => {}
				);
			},
			//获取发票地址
			getInvoiceAddress() {
				this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-invoice-address', {}).then(
					res => {
						// console.log("获取发票地址", res);
						// this.orderList.forEach((childitem, childindex) => {
						// 	console.log('res.obj', res.obj);
						// 	childitem.invoiceInformation.feMail = res.obj.feMail;
						// 	childitem.invoiceInformation.fLinkman = res.obj.fLinkman;
						// 	childitem.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
						// 	childitem.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						// 	childitem.invoiceInformation.invoiceInformationTipsShow = false;
						// });
						console.log("获取发票地址", this.orderList);
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addInvoiceForm = res.obj;
						this.invoiceInformation.feMail = res.obj.feMail;
						this.invoiceInformation.fLinkman = res.obj.fLinkman;
						this.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
						this.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
					},
					error => {}
				);
			},
			//发票信息显示
			invoiceInformationTipsShowClick(index) {
				this.invoiceInformation.invoiceInformationTipsShow = !this.invoiceInformation.invoiceInformationTipsShow;
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},



			//数量加减
			valChange(orderData, i) {
				console.log('数量加减orderData', orderData);
				console.log('数量加减i', i);
			console.log(orderData)
			if (!orderData.fPlanAmount) {
				this.orderList[i].fPlanAmount = 0
			}
			// if (orderData.fPlanAmount % orderData.fAdjustmentRange != 0 && orderData.fAdjustmentRange != 0) {
			// 	this.orderList[i].fPlanAmount = (orderData.fPlanAmount / orderData.fAdjustmentRange).toFixed(0) * orderData.fAdjustmentRange;
			// }
			// console.log('数量加减orderData', orderData);
			if (this.orderList[i].carrierDtos) {
				let fMinAmount = 0;
				this.orderList[i].carrierDtos.forEach((item, index) => {
					if (orderData.carrierData == item.fCarrierID && item.fCarrierID != -1) {
						fMinAmount = item.fMinAmount;
						if (orderData.fIsNeedFInvoiceStatus == 0) {
							this.orderList[i].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						} else {
							this.orderList[i].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						}
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					}
				});
				// console.log('fFreightPrice', fFreightPrice);
				if (this.orderList[i].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount + ',请重新选择承运商');
					this.orderList[i].carrierData = -1;
					this.orderList[i].fCarrierID = -1;
					this.orderList[i].freightAmount = 0;
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					return;
				}
			}
			},

			// 商品数量进步器 光标消失时调用
			valBlur(orderData, index) {
				// if (orderData.fPlanAmount == 0) {
				// 	return;
				// }
				// // 最小销量：fMinSaleAmount 销量调整幅度：fAdjustmentRange 销量小数位数：fPrecision
				// // 是否整除 isNumDivisible 
				// let isNumDivisible = orderData.fPlanAmount % orderData.fAdjustmentRange == 0 ? true : false
				// if (isNumDivisible == false) {
				// 	// 除后的值 valueAfterDivision    输入量 除以 调整幅度
				// 	let valueAfterDivision = (orderData.fPlanAmount * 100 / orderData.fAdjustmentRange * 100) / 100 / 100
				// 	// 更正后的值  除后的值四舍五入 乘以 调整幅度
				// 	this.orderList[index].fPlanAmount = valueAfterDivision.toFixed(0) * orderData.fAdjustmentRange
				// }
			},

			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},


			//结算创建订单
			updateOrder() {
				if (this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addAddress();
					return;
				}
				var falg = false;
					this.orderList.forEach((val,index)=>{
						if (val.fStatus == 2) {
							falg = true;
						}
					});
					if(falg == true){
						 this.$message('亲，当前商品已售罄，无法购买');
						 return
					}
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				if (findData) {
					// this.$message('请维护地址或者选择地址！');
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.carrierData == '-1')) {
					this.$message('请选择承运商');
					return;
				}
				// 提交参数
				this.submitList = [];
				// 提交成功数组
				this.submitSuccessList = [];
				this.orderList.forEach((item, index) => {
					let params = {
						orderId: item.fOrderID,
						fBusinessTypeID: item.fBusinessTypeID,
						fGoodsID: item.fGoodsID,
						fAddressID: item.fAddressID,
						fUsePoint: 0,
						fDeliveryID: item.fDeliveryID,
						fCarrierID: item.fCarrierID,
						fCouponCustomerReceiveRecordID: -1,
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus,
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus,
						fPlanAmount: item.fPlanAmount,
						fAppTypeID: this.fAppTypeID
					}
					this.submitList.push(params);
				});
				this.submitOrderList();
			},
			// 更新订单接口
			submitOrderList() {
				this.submitSuccessList = [];
				this.ApiRequestPutNOMess('api/mall/ebsale/order/update-b2c-batch', this.submitList)
					.then(
						res => {
							console.log('更新订单接口', res.obj);
							// this.submitSuccessList.push(res.obj);
							if (res.message == "") {
								let paramsData = [];
								let orderIds = [];
								for (let item of this.orderIDList) {
									paramsData.push(item.id.toString())
									// this.submitSuccessList.push(res.obj)
								}
								this.$router.replace({
									name: "PaymentOrder",
									params: {
										orderIds: paramsData,
										fBillTypeID: this.orderList[0].fBillTypeID
									}
								})
							} else {
								// 如果失败则提示用户是否继续
								res.message = res.message.split(';');
								let createTipCode = res.message[1];
								let createMessage = res.message[0];
								this.$confirm(createMessage + ', 是否继续?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									if (createTipCode == '001') {
										this.$store.dispatch("setShowAttestation", true);
									} else if (createTipCode == '002') {
										this.addInvoice('Add');
									} else if (this.createTipCode == '003') {
										if (this.getUserInfo.fCommerceTypeID == '1') {
											this.$router.push({
												path: '/businessme/individualUsersBindingCard',
												query: {
													title: '开通账服通',
													type: 2,
													fAccountname: '账服通'
												}
											}) //跳转个人用户绑卡
										} else if (this.getUserInfo.fCommerceTypeID == '2') {
											this.$router.push({
												path: '/businessme/enterpriseUsersBindingCard',
												query: {
													title: '开通账服通',
													type: 2,
													fAccountname: '账服通'
												}
											}) //跳转企业用户绑卡
										}
									} else if (createTipCode == '004') {
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									} else if (createTipCode == '005') {
										// this.getCustomerPoint();
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									} else if (createTipCode == '006') {
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									}
								}).catch(() => {});
							}
						},
						error => {}
					);
			},
			// 生成支付单
			async creatPayList() {
				console.log("生成支付单");
				let orderIds = []
				await this.submitSuccessList.forEach(item => {
					orderIds.push(item.fOrderID.toString())
				})
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						orderIds: orderIds,
						fBillTypeID: this.orderList[0].fBillTypeID
					}
				})
			},
			//议价的询问验证
			talkAboutPrice(){
				this.$confirm('是否确认该笔订单与承运商议价?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.speackPrice()
				        }).catch(() => {       
				        });
			},
			//承运商议价
			asktalkAboutPrice(){
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				for (let item of this.orderList) {
					
									if (item.deliveryDtos.length >0) {
										for (let item1 of item.deliveryDtos) {
											if (item1.fDeliveryID == -1) {
												this.$message('商品不在当前收货地址销售，请重新选择收货地址');
												return;
											}
										}
									} else {
										this.$message('商品不在当前收货地址销售，请重新选择收货地址');
										return;
									}
									console.log(item)
									if(item.fDeliveryID != 1){
										this.$message('订单中存在不可以议价的订单，请确认订单详细情况');
										return;
									}
				
								}
				if (findData) {
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.carrierData == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				// 提交参数
				this.submitListPrice = [];
				// 提交成功数组
				this.submitSuccessListPrice = [];
				this.orderList.forEach((item, index) => {
					if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID == '暂无可用') {
						item.fCouponCustomerReceiveRecordID = -1;
					}
					console.log(item,'555555')
					let params = {
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						// fUsePoint: item.fUsePoint,
						fDeliveryID: item.fDeliveryID, // 配送方式ID
						fCarrierID: item.fCarrierID, // 承运商ID
						// fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 : item.fCouponCustomerReceiveRecordID, // 优惠券ID
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
						fPlanAmount: item.fPlanAmount, // 商品数量
						fAppTypeID: this.fAppTypeID, // 应用类型ID
						fBusinessTypeID: item.fBusinessTypeID ,// 运输方式ID
						// fcFreightPrice: item.fFreightPriceTax,
						// fFreightPriceID: item.fFreightPriceID,
						//=====================================
					}
					this.submitListPrice.push(params);
				});
				this.talkAboutPrice();
			
			},
			//调用承运商议价的接口
			async speackPrice() {
				let forlength = this.submitListPrice.length;
				let forIndex = JSON.parse(JSON.stringify(this.needPriceIndex))
				this.orderLoading = true
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitListPrice[index]
					// 异步调用API方法，以达到对成功的订单分段控制
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-bargaining-b2b-order', item)
						.then(
							res => {
								console.log(res)
                if (res.obj !== null) {
                  this.needPriceIndex = index + 1;
                  this.resultdata.push(res.obj)
                  if (this.needPriceIndex == this.submitListPrice.length) {
                    var colleteList = []
                    for(let item of this.resultdata){
                      colleteList.push({
                        fOrderNumber:item.fOrderNumber,
                        fCarrierName:item.fCarrierName,
                        fCarrierPhone:item.fCarrierPhone,
                      })
                    }
                    console.log(colleteList)
                    // 跳转到议价单页面
                    this.goToUnderReview(colleteList);
                  }
								} else {
                  let str = res.message
                  let arr = str.split(';')
                  console.log(arr)
                  if (arr[1] === '001' || arr[1] === '002' || arr[1] === '003') {
                    this.$confirm(createMessage + ', 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      if (arr[1] == '001') {
                        this.$store.dispatch("setShowAttestation", true);
                      } else if (arr[1] == '002') {
                        this.addInvoice('Add');
                      } else if (arr[1] == '003') {
                        if (this.getUserInfo.fCommerceTypeID == '1') {
                          this.$router.push({
                            path: '/businessme/individualUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转个人用户绑卡
                        } else if (this.getUserInfo.fCommerceTypeID == '2') {
                          this.$router.push({
                            path: '/businessme/enterpriseUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转企业用户绑卡
                        }
                      }
                    })
                  } else {
                    this.$message(arr[0])
                  }
                  // if (arr[arr.length-1] === '001')
                  this.orderLoading = false
                }
								
								
							},
							error => {
                this.$message.error(error)
                this.orderLoading = false
							}
						);
				}
			},
			//跳转审核中页面
			goToUnderReview(val) {
				console.log('议价审核中');
				// this.$router.push("/about/Allperfect");
				this.orderLoading = false
				this.$router.push({
					name: "bargainPrice",
					params:{fname: JSON.stringify(val)},
				});
			},

			//展开关闭其他地址
			noDefaultAddressShow(index) {
				this.addressIndex = index;
				this.addressStatus = !this.addressStatus;
			},
			//选择其他地址
			addressChoose(obj, activeindex) {
				if(this.orderList[activeindex].fGovernmenAreaID != obj.fGovernmenAreaID){
					var changeAddress = true
				}
				this.chooseAddress = obj;
				this.orderList[activeindex].address = obj;
				this.orderList[activeindex].fConsignee = obj.fLinkman;
				this.orderList[activeindex].fConsigneePhone = obj.fLinkPhone;
				this.orderList[activeindex].fDeliveryAddress = obj.fFullPathAddress;
				this.orderList[activeindex].fAddressID = obj.fAddressID;
				this.orderList[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.getDeliveryByAreaid(changeAddress);
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//新增地址
			addAddress() {
				this.pageTitle = "新增";
				this.addVisible = true;
			},
			//编辑地址
			EditAddress(item) {
				console.log('item', item);
				this.pageTitle = "编辑";
				console.log('item', item);
				this.ApiRequestPostNOMess("api/mall/eb-customer/address/get", {
						id: item.fAddressID
					})
					.then(res => {
						console.log('编辑地址', res);
						res.obj.fIsDefault = res.obj.fIsDefault == 0 ? false : true
						res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addressForm = res.obj;
						console.log('this.addressForm', this.addressForm);
						this.addVisible = true;
					}, error => {});
			},
			//新增或者编辑地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
						if (this.pageTitle == "编辑") {
							console.log('this.addressForm', this.addressForm);
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.rowStatus = 16;
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									// console.log(result);
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						} else if (this.pageTitle == "新增") {
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: 1,
								fCustomerID: 0,
								feMail: "",
								fGovernmenAreaID: this.addressForm.fGovernmenAreaID,
								fAddress: this.addressForm.fAddress,
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1,
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0,
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
							}).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						}
					} else {
						return false;
					}
				});
			},
			handleClose() {
				this.addVisible = false;
				this.resetForm();
			},
			//重置form表单
			resetForm() {
				this.addressForm = {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: 1,
					fIsDefault: false
				}
			},

			addInvoiceClose() {
				this.addInvoiceStatus = false;
				this.addInvoiceForm = {};
			},
			addInvoice(type) {
				this.invoiceType = type;
				if (type == 'Edit') {
					this.getInvoiceAddress();
				}
				this.addInvoiceStatus = true;
			},

			//保存收件信息
			invoiceSubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.invoiceType == 'Add') {
							this.addInvoiceForm.fAddressID = 0;
						}
						/* this.addInvoiceForm.fGovernmenAreaID = this.addInvoiceForm.area[this.addInvoiceForm.area.length-1];
						console.log(this.addInvoiceForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							fAddressID: this.addInvoiceForm.fAddressID,
							feMail: this.addInvoiceForm.feMail,
							fGovernmenAreaID: this.addInvoiceForm.area[this.addInvoiceForm.area.length - 1],
							fAddress: this.addInvoiceForm.fAddress,
							fLinkman: this.addInvoiceForm.fLinkman,
							fLinkPhone: this.addInvoiceForm.fLinkPhone
						}).then(
							result => {
								console.log(result);
								this.getInvoiceInfo();
							},
							rej => {}
						);
					} else {
						return false;
					}
				});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;
		// transition: all 0.5s ease;
		// &::after {
		// 	content: '✔';
		// 	display: block;
		// 	height: 0px;
		// 	width: 0px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	right: 0;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	line-height: 18px;
		// 	border: 18px solid;
		// 	// border-color: transparent #55aaff #55aaff transparent;
		// 	@include themify($themes) {
		// 		border-color: transparent themed('themes_color') themed('themes_color') transparent;
		// 	}
		// }
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-top-F2F2F2 .el-input__inner {
		border-radius: 0;
		width: 196px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 196px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-top: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		padding-top: 5px;
	}
</style>
